<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Modo - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
             
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select ref="city" v-model="moso.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                  <small v-if="errors.document_type"  class="form-text text-danger" >Seleccione un tipo de documento</small>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input v-model="moso.document_number" class="form-control" ></b-form-input>
                      <b-input-group-append>
                        <b-button variant="primary"  @click="SearchMoso"><b-icon icon="search"></b-icon></b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small v-if="errors.document_number" class="form-text text-danger" >{{error_document_number}}</small>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Nombres:">
                    <b-form-input type="text" ref="name" v-model="moso.name" placeholder="Ingrese su nombre"></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger" >Ingrese un nombre o razón social</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Token:">
                    <b-form-input type="password" ref="token" v-model="moso.token"></b-form-input>
                    <small class="form-text text-ino" >ingresar para cambiar el token</small>
                  </b-form-group>
                </b-col>
           
                <b-col md="3">
                  <b-form-group label="Usuario :" >
                    <b-form-input type="text" v-model="moso.description" ></b-form-input>
                    <small v-if="errors.description" class="form-text text-danger" >Ingrese un nombre de usuario</small>
                  </b-form-group>
                </b-col>
          
                <b-col md="2">
                  <b-form-group label="Teléfono :">
                    <b-form-input type="text" v-model="moso.phone"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Email :">
                    <b-form-input type="email"  ref="email" v-model="moso.email"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select  v-model="moso.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>
         

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'

export default {
  name: "ClientEdit",
  props: ["id_moso"],
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  
  data() {
    return {
      module:'Moso',
      role:3,
      isLoading: false,
      moso: {
        id_moso: "",
        document_type: "1",
        document_number: "",
        name: "",
        token: "",
        description: "",
        phone: "",
        email: "",
        state: 1,
      },

      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
        token: false,
      },

      error_document_number:'',
      validate: false,
    };
  },
  mounted() {
    this.ViewMoso();
  },
  methods: {
    ViewMoso,
    EditMoso,
    Validate,
    SearchMoso,
  
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function SearchMoso() {

  let me = this;
  if (me.moso.document_type == 1) {
      if (me.moso.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.moso.document_number).then((data) => {
          if (data.status == 200) {
            me.moso.name = data.razon_social;
            me.moso.address = data.direccion;
          }else{
            me.moso.name = '';
            me.moso.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.moso.document_type == 6) {
      if (me.moso.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.moso.document_number).then((data) => {
          if (data.status == 200) {
            me.moso.name = data.razon_social;
            me.moso.address = data.direccion;
          }else{
            me.moso.name = '';
            me.moso.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

//ver usuario
function ViewMoso() {
  let me = this;
  let id_moso = je.decrypt(me.id_moso);
  let url = me.url_base + "moso/view/" + id_moso;
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.moso.id_moso = response.data.result.id_moso;
        me.moso.document_type = response.data.result.document_type;
        me.moso.document_number = response.data.result.document_number;
        me.moso.name = response.data.result.name;
        me.moso.token = '';
        me.moso.phone = response.data.result.phone;
        me.moso.description = response.data.result.description;
        me.moso.email = response.data.result.email;
        me.moso.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditMoso() {
  let me = this;
  let url = me.url_base + "moso/edit";
  let data = me.moso;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token, module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {

 this.validate = false;
  if (this.moso.document_type == 1) {
    this.errors.document_number = this.moso.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.moso.document_type == 6){
    this.errors.document_number = this.moso.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.moso.document_type == 4){
    this.errors.document_number = this.moso.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.moso.document_type == 7){
    this.errors.document_number = this.moso.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.moso.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.document_type = this.moso.document_type.length == 0 ? true : false;
  this.errors.name = this.moso.name.length == 0 ? true : false;


  if (this.errors.document_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.document_number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }



  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar el moso?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditMoso(me);
      }
    });

  }

}
</script>
